html {
  -webkit-text-size-adjust: 100%;
  /* Prevents iOS text size adjust after orientation change, without disabling user zoom */
  -ms-text-size-adjust: 100%;
  /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a:focus {
  outline: none;
}
a:hover,
a:active {
  outline: 0;
}
a img {
  border: 0;
}
a {
  text-decoration: none;
}
input:focus,
textarea:focus {
  outline: none;
}
img,
video {
  max-width: 100%;
  vertical-align: top;
}
audio,
canvas,
video {
  display: inline;
  zoom: 1;
}
body,
button,
input,
select,
textarea {
  font-family: 'OpenSans', sans-serif;
  line-height: 1.5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'OpenSans', sans-serif;
  font-weight: 700;
  clear: both;
  line-height: 1;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
a {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #4280bf;
}
a:hover {
  color: #dcf836;
}
p {
  margin-bottom: 10px;
  font-size: 50px;
}
b,
strong {
  font-weight: 700;
}
dfn,
cite,
em,
i {
  font-style: italic;
}
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
}
:-moz-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
}
::-moz-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
  opacity: 1;
  /* Since FF19 lowers the opacity of the placeholder by default */
}
:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
}
textarea {
  max-width: 100%;
}
select {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}
p {
  font-family: 'Nunito', sans-serif;
  /*font-size: 14px;*/
  font-weight: 300;
  text-transform: none;
  line-height: 24px !important;
}

/* Specific MoultDB
***********************/
.container{
  margin-bottom: 15px;
}

#moulting-content {
  background: url('./assets/images/uploads/background.png') fixed center;
  height: 100vh;
  overflow-y: scroll;
}
#moulting-body {
  padding-top: 130px !important;
}
.slider {
  text-align: center;
  background-size: cover;
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: none
}

.description {
  font-family: system-ui, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
.description h1 {
  margin-bottom: 15px;
}

.bg-custom-dark {
  background-color: #020d18;
}

main {
  /*margin-top: 60px;*/
}
body {
  color: white
}
h1 {
  text-align: center;
  padding-bottom: 20px;
}
ul {
  display: inline-block;
  text-align: left;
}

.required:after {
  content:" *";
  color: red;
}

#moulting-content:after {
  z-index: 10000;
  content: "beta";
  position: fixed;
  width: 100px;
  height: 25px;
  background: darkred;
  top: 12px;
  left: -23px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 26px;
  transform:rotate(-45deg);
}
