.tutorial.container {
  max-width: 900px;
  margin: auto;
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: white;
}

.tutorial.container p {
  margin-bottom: 30px;
}

.tutorial > h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.tutorial > h2 {
  font-size: 24px;
  color: #007bff;
  margin-bottom: 10px;
}


.tutorial-step {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.tutorial-step p {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.6;
  color: black;
}

.tutorial-step h2{
  color:#3366CC;
}


.tutorial-step img {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
}


.tutorial > .footer-note {
  font-size: 16px;
  color: white;
  margin-top: 20px;
}
