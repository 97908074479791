/* Wrapper per centrare tutto nella griglia */
.loader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px; /* Evita che lo spazio collassi */
  }
  
  /* Container per mantenere i pallini in posizione originale */
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  /* Pallini animati (restano come prima) */
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffa516; /* Colore arancione */
    animation: bounce 1.2s infinite ease-in-out;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
  }
  
  .loading-text {
    margin-top: 15px;
    font-size: 16px;
    color: #555;
    font-weight: bold;
    text-align: center;
  }
  