@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
select[multiple] {
  height: auto;
}
select,
input[type="text"] {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #aaa;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.3s ease-out;
  -moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.3s ease-out;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.3s ease-out;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.3s ease-out;
}
select:focus,
input[type="text"]:focus {
  border-color: #66afe9;
  outline: 0;
}
select::-moz-placeholder,
input[type="text"]::-moz-placeholder {
  color: #999;
  opacity: 1;
}
select:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder {
  color: #999;
}
select::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder {
  color: #999;
}
select:-moz-placeholder,
input[type="text"]:-moz-placeholder {
  color: #999;
}
select::-moz-placeholder,
input[type="text"]::-moz-placeholder {
  color: #999;
}
select:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder {
  color: #999;
}
select::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder {
  color: #999;
}
@-ms-viewport {
  width: device-width;
}


html,
body,
div,
h4,
form,
label {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}

input:focus {
  outline: none;
}
h4 {
  margin: 0;
}
html {
  font-size: 10px;
}
body {
  font-size: 150px;
  font-size: 15rem;
  font-size: 15px;
  font-size: 1.5rem;
  color: #333;
}
body,
input,
select {
  font-family: 'OpenSans', sans-serif;
  line-height: 1.5;
}
h4 {
  font-family: 'OpenSans', sans-serif;
  font-weight: 700;
  clear: both;
  line-height: 1;
}
h4 {
  font-size: 20px;
}
::-webkit-input-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
}
:-moz-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
}
::-moz-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
  opacity: 1;
}
:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.7);
  font-family: "Noto Sans", sans-serif;
}

select {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}

.sidebar{
  margin-top: 15px;
}

.sidebar {
  margin-left: 60px;
}
.sidebar .sb-title {
  font-family: 'Dosis', sans-serif;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #405266;
}

.form-style-1 {
  margin-bottom: 60px;
  background-color: #0b1a2a;
  border: 3px solid #0f2133;
  padding: 15px;
}
.form-style-1 .form-it {
  margin-bottom: 15px;
}
.form-style-1 label {
  font-family: 'Dosis', sans-serif;
  font-size: 14px;
  color: #abb7c4;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 10px;
}
.form-style-1 input,
.form-style-1 select {
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  color: #abb7c4;
  font-weight: 300;
  text-transform: none;
  background-color: #233a50;
  border: none;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  height: 40px;
}
.form-style-1 input::-webkit-input-placeholder {
  color: #abb7c4;
}
.form-style-1 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Firefox */
  background: url('../../assets/images/uploads/drop-icon.png') no-repeat right 20px center;
  background-color: #233a50;
}
@media (max-width: 991px) {
  .form-style-1 select {
    margin-bottom: 30px;
  }
}
.form-style-1 input.submit {
  font-family: 'Dosis', sans-serif;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  background: #dd003f;
  width: 100%;
}
.form-style-1 .group-ip {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: row;
}
