header {
    position: absolute;
    z-index: 100;
    width: 100%;
    background-color: rgba(15, 33, 51, 0.8);
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
    header {
        background-color: #0f2133;
    }
}
header .nav-link {
    font-family: 'Dosis', sans-serif;
    font-size: 16px;
    color: #abb7c4;
    font-weight: bold;
    cursor: pointer;
    border-bottom: none;
}
header .nav-link:hover, .nav-link:focus {
    color: #dcf836;
}

header .dropdown-toggle:active {
    color: #dcf836;

}
header .dropdown-menu .dropdown-item {
    color: #333;
    font-family: 'Dosis', sans-serif;
    font-size: 14px;
    font-weight: bold;
}
header .dropdown-menu .dropdown-item:hover {
    color: #dcf836;
    background-color: #233a50;
}
@media (min-width: 768px) {
    header .nav-link {
        --bs-navbar-nav-link-padding-x: 15px;
        --bs-navbar-nav-link-padding-y: 15px;
        --bs-nav-link-padding-y: 15px;
        --bs-nav-link-padding-x: 15px;
    }
}

header .navbar-end {
    position: absolute;
    left: auto;
    right: 0;
}

header .signupLink {
    background-color: #dd003f;
    color: #ffffff;
    padding: 11px 25px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    margin: 4px;
}

header .top-search {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 4px solid #020d18;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
header .top-search select,
header .top-search input {
    line-height: 1.42857143;
    background-image: none;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    font-size: 14px;
    padding: 6px 12px;
    width: 100%;
    height: 46px;
    border: none;
    background-color: #233a50 !important;
    font-family: 'Nunito', sans-serif;
    color: #abb7c4;
    font-weight: 300;
    text-transform: none;
}
header .top-search select {
    width: 20%;
    border-right: 1px solid #020d18;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../assets/images/uploads/drop-icon.png') no-repeat right 20px center;
    text-transform: uppercase;
    color: #ffffff;
}
header .top-search input::-webkit-input-placeholder {
    color: #abb7c4;
}
header .top-search input {
    background: url('../../assets/images/uploads/topsearch.png') no-repeat right 20px center;
}

@media (max-width: 991px) {
    .top-search select {
        width: 40% !important;
    }
}
@media (max-width: 767px) {
    .top-search {
        display: none !important;
    }
}

.sticky {
    background-color: #0f2133;
    position: fixed;
    width: 100%;
    z-index: 9999;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
    filter: alpha(opacity=100);
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}
.sticky .navbar-default {
    padding: 12px 0;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}
.sticky .top-search {
    display: none;
}

