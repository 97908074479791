
#privacy-notice {
    color: whitesmoke !important;
}

#privacy-notice h1, #privacy-notice h2 {
    margin-bottom: 20px;
}

#privacy-notice h2 {
    border-bottom: 1px solid #2c3e50;
}

#privacy-notice p {
    color: whitesmoke;
    font-size: 100%;
    font-weight: normal;
}


#privacy-notice ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 0;
}

#privacy-notice ul li {
    margin-bottom: 10px;
}

/* Links */
#privacy-notice a {
    color: #4280bf;
    text-decoration: none;
}

#privacy-notice a:hover {
    text-decoration: underline;
    color: #dcf836;
}