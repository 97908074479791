
.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-link a {
    border-bottom: none;
}
.social-link a svg {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #ffffff;
    font-size: 16px;
    margin-right: 15px;
}
.social-link a svg:hover {
    color: #dcf836;
}
